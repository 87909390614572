<template>
  <v-card>
    <h3 class="pa-3 text-center white--text" style="background-color: #2B3C4F;">{{ companyData.name }}</h3>
    <v-row class="pa-5" align="center">
      <v-col cols="2" class="flex-grow-1 flex-shrink-0">
        <v-img v-if="companyData.logo" id="logo" :src="companyLogo" contain max-width="128" max-height="128" class="grey lighten-3" style="background-color: #00000000 !important;"></v-img>
        <v-img v-else src="@/assets/logo-empty.png" contain max-width="128" max-height="128" class="grey lighten-3"></v-img>
      </v-col>
      <v-col cols="10" class="flex-grow-0 flex-shrink-1 pl-5">
        <div><strong>Employees:</strong> <span v-for="(employee, i) in companyData.employees" :key="i">{{ employee.personName + (i === companyData.employees.length - 1 ? "" : ", ") }}</span></div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  export default {
    name: 'CompanyThumbnail',

    props: ['companyData'],

    data () {
      return {
        companyLogo: null
      }
    },

    created () {
      // Retrieve logo
      if (!this.companyData.logo) return
      this.loading = true
      this.$store.dispatch('getLogo', this.companyData.logo)
      .then((resp) => {
        this.companyLogo = resp
        this.loading = false
      })
      .catch((error) => {
        this.error = error
        this.loading = false
        if (this.error.code == 'permission-denied') {
          this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
        }
      })
    }
  }
</script>
