<template>
  <v-card class="pa-5 w-100" align="center">
    <v-form v-model="valid" ref="form" @submit.prevent="submit">
      <!-- Error -->
      <v-row v-if="error" justify="center" class="mb-5 mt-5 red--text">
        {{ error }}
      </v-row>
      <!-- Controls -->
      <v-row justify="center">
        <v-btn :type="'submit'" :loading="loading" color="primaryButton" class="white--text">
          Submit
        </v-btn>
      </v-row>
      <!-- Header -->
      <h2 class="mt-5 text-center">{{ formData.idCompany ? 'Edit' : 'Create' }} Company</h2>
      <!-- Basic Info -->
      <v-row no-gutters flex-wrap class="mt-5">
        <v-col no-gutters cols="6" class="pl-5 pr-2">
          <v-text-field v-model="formData.idCompany" label="ID" disabled></v-text-field>
        </v-col>
        <v-col no-gutters cols="6" class="pl-2 pr-5">
          <v-file-input v-model="logoFile" label="Logo" prepend-icon="mdi-camera" accept="image/*"></v-file-input>
        </v-col>
        <v-col no-gutters cols="12" class="pl-5 pr-5">
          <v-text-field v-model="formData.name" label="Name" :rules="requiredRules" :hint="formData.idCompany == undefined ? 'Public-facing' : 'Check with sysadmin to change company name'" :disabled="formData.idCompany != undefined" persistent-hint></v-text-field>
        </v-col>
      </v-row>
      <!-- Employees -->
      <h3 class="mt-5 text-center">Employees</h3>
      <v-btn
        @click="addEmployee"
        :loading="loading"
        color="primaryButton"
        class="mt-5 white--text"
      >
        Add Employee
      </v-btn>
      <div v-if="users.length > 0">
        <v-card v-for="(employee, i) in formData.employees" :key="i" color="card2" class="mt-5 pa-5 pl-2 pr-2">
          <v-row no-gutters flex-wrap>
            <v-col no-gutters cols="12" class="pl-5 pr-5">
              <v-autocomplete
                v-model="formData.employees[i]"
                label="Search by Display Name"
                :items="users"
                item-text="personName"
                item-value="idPerson"
                :disabled="loading"
                return-object
                @change="onSelectedEmployee(i, formData.employees[i])"
                required
              />
            </v-col>
            <v-col no-gutters cols="6" class="pl-5 pr-2">
              <v-text-field
                v-model="employee.idPerson"
                label="ID"
                disabled
              ></v-text-field>
            </v-col>
            <v-col no-gutters cols="6" class="pl-2 pr-5">
              <v-text-field
                v-model="employee.email"
                label="Email"
                disabled
              ></v-text-field>
            </v-col>
            <v-col no-gutters cols="6" class="pl-5 pr-2">
              <v-text-field
                v-model="employee.personName"
                label="Display Name"
                disabled
              ></v-text-field>
            </v-col>
            <v-col no-gutters cols="6" class="pl-2 pr-5">
              <v-select
                v-model="employee.idPosition"
                :items="employeePositions"
                item-value="idCompanyEmployeePosition"
                item-text="name"
                label="Position"
                :rules="requiredRules"
              ></v-select>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn @click=navigateToUser(employee.idPerson) :disabled="loading || !employee.idPerson" class="mr-2" color="primaryButton">
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
            <v-btn @click="removeEmployee(employee)" :disabled="loading" class="ml-2" color="red">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </v-row>
        </v-card>
      </div>
    </v-form>
  </v-card>
</template>

<script>
  export default {
    name: 'CompanyForm',

    props: ['inputData'],

    data () {
      return {
        employeePositions: [],
        error: '',
        formData: {},
        loading: false,
        logoFile: null,
        requiredRules: [
          v => !!v || 'Field is required.'
        ],
        users: [],
        valid: false
      }
    },

    created () {
      this.formData = this.inputData
      if (this.users.length == 0 && this.formData.employees) {
        this.getUsers()
        this.getEmployeePositions()
      }
    },

    watch: {
      inputData () {
        this.formData = this.inputData
        if (this.users.length == 0 && this.formData.employees) {
          this.getUsers()
          this.getEmployeePositions()
        }
      }
    },

    methods: {
      addEmployee () {
        if (this.users.length == 0) {
          this.getUsers()
          this.getEmployeePositions()
        }
        if (!this.formData.employees) {
          this.formData.employees = []
        }
        this.formData.employees.unshift({ })
        this.$forceUpdate()
      },

      getEmployeePositions () {
        this.loading = true
        this.$store.dispatch('getCompanyEmployeePositions')
        .then((resp) => {
          this.employeePositions = resp
          this.loading = false
          this.$forceUpdate()
        })
        .catch((error) => {
          this.error = error
          this.loading = false
          if (this.error.code == 'permission-denied') {
            this.$store.dispatch('logout')
            .then(() => {
              this.$router.push('/login')
            })
          }
        })
      },

      getUsers () {
        this.loading = true
        this.$store.dispatch('getUsers')
        .then((resp) => {
          this.users = resp.sort((a, b) => a.personName.toLowerCase() > b.personName.toLowerCase() ? 1 : -1)
          this.loading = false
          this.$forceUpdate()
        })
        .catch((error) => {
          this.error = error
          this.loading = false
          if (this.error.code == 'permission-denied') {
            this.$store.dispatch('logout')
            .then(() => {
              this.$router.push('/login')
            })
          }
        })
      },

      onSelectedEmployee(index, employee) {
        this.formData.employees[index] = employee
        this.$forceUpdate()
      },

      navigateToUser(id) {
        const routeData = this.$router.resolve({ path: '/users', query: { id: id } });
        window.open(routeData.href, '_blank');
      },

      removeEmployee(employee) {
        const index = this.formData.employees.indexOf(employee)
        if (index > -1) {
          this.formData.employees.splice(index, 1)
          this.$forceUpdate()
        }
      },

      submit () {
        this.error = ''
        this.$refs.form.validate()
        if (this.valid && !this.loading) {
          this.loading = true
          const dispatchType = this.formData.idCompany ? 'updateCompany' : 'createCompany'
          var dispatchParams = this.formData;

          if (this.logoFile) dispatchParams.logo = this.logoFile
          else delete dispatchParams.logo

          this.$store.dispatch(dispatchType, dispatchParams)
          .then((companyData) => {
            this.formData = companyData
            this.loading = false
          })
          .catch((error) => {
            this.error = error
            this.loading = false
            if (this.error.code == 'permission-denied') {
              this.$store.dispatch('logout')
              .then(() => {
                this.$router.push('/login')
              })
            }
          })
        }
      },

      validateForm() {
        this.$refs.form.validate()
      }
    }
  }
</script>
