<template>
  <v-row justify="center">
    <v-col cols="5" class="mb-5">
      <!-- Header -->
      <h2 class="pt-5 text-center">Companies</h2>
      <!-- Loading -->
      <v-row v-if="loading" class="pt-5" justify="center">
        <v-icon>mdi mdi-loading mdi-spin</v-icon>
      </v-row>
      <!-- Error -->
      <v-row v-if="error" class="pt-5 red--text" justify="center">
        {{ error }}
      </v-row>
      <!-- Controls -->
      <v-row class="pt-5" align="center">
        <v-autocomplete v-model="selectedCompany" label="Search by Company Name" :items="companies" item-text="name" :disabled="loading" return-object/>
        <v-btn @click="selectedCompanyData={}" color="primaryButton" class="ml-5 white--text">+ New Company</v-btn>
      </v-row>
      <!-- Company Form -->
      <CompanyForm v-if="selectedCompanyData" :inputData="selectedCompanyData" class="mb-5"></CompanyForm>
      <!-- Thumbnails -->
      <v-card v-for="(company, i) in companies" :key="i" @click="selectedCompany = company" class="ma-0 pa-0">
        <CompanyThumbnail :companyData="company" :key="componentKey" class="mb-5" style="width: 100%;"></CompanyThumbnail>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import CompanyForm from "@/components/CompanyForm"
  import CompanyThumbnail from "@/components/CompanyThumbnail"

  export default {
    name: 'Companies',

    components: {
      CompanyForm,
      CompanyThumbnail
    },

    data () {
      return {
        companies: [],
        componentKey: 0,
        error: '',
        loading: false,
        selectedCompany: null,
        selectedCompanyData: null
      }
    },

    created () {
      this.getCompanies()
    },

    watch: {
      selectedCompany () {
        window.scroll({ top: 0, behavior: 'smooth' })
        this.getSelectedCompanyData()
      }
    },

    methods: {
      getCompanies () {
        this.loading = true
        this.$store.dispatch('getCompanies')
        .then((resp) => {
          this.loading = false
          if (resp.errors) {
            this.error = resp.errors
          } else {
            this.companies = resp.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
            window.scroll({ top: 0, behavior: 'smooth' })
            this.componentKey++; // required in order to force refresh the thumbnails, otherwise the logos will appear in the wrong thumbnails
          }
        })
        .catch((error) => {
          this.error = error
          window.scroll({ top: 0, behavior: 'smooth' })
          this.loading = false
        })
      },

      getSelectedCompanyData () {
        this.$store.dispatch('getCompanyByID', this.selectedCompany.idCompany)
        .then((resp) => {
          this.selectedCompanyData = resp
          this.loading = false
        })
        .catch((error) => {
          this.error = error
          this.loading = false
          if (this.error.code == 'permission-denied') {
            this.$store.dispatch('logout')
            .then(() => {
              this.$router.push('/login')
            })
          }
        })
      }
    }
  }
</script>